<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Dashboard</span>
				</router-link>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ $route.meta.title }}
					</h2>
					<div class="ng-block-des d-print-none">
						<p>You can find all of your Pallet/AKE types</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Type to search..."
									v-model="search"
									@keyup="searchRecords"
								/>
							</div>
						</li>
						<li>
							<a
								href="#newItem"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-plus-c"></em>
								<span>
									<span class="d-none d-sm-inline-block">
										Add
									</span>
									New
								</span>
							</a>
							<div class="modal fade" tabindex="-1" id="newItem">
								<div class="modal-dialog" role="document">
									<form
										class="modal-content"
										@submit.prevent="createRecord"
									>
										<button
											id="hideNewForm"
											class="close"
											data-dismiss="modal"
											aria-label="Close"
										>
											<em
												class="icon ni ni-cross text-white"
											></em>
										</button>

										<div class="modal-header bg-primary">
											<h5
												class="modal-title text-white"
												v-if="record.name"
											>
												Adding {{ record.name }}
											</h5>
											<h5
												class="modal-title text-white"
												v-else
											>
												Add New Type
											</h5>
										</div>

										<div class="modal-body form row">
											<div class="form-group col-md-12">
												<label>Shipping Format Name</label>
												<input
													class="form-control"
													v-model="record.name"
												/>
											</div>
											<div class="form-group col-md-6 d-none">
												<label>Slug</label>
												<input
													class="form-control"
													v-model="slug"
												/>
											</div>
											<div class="form-group col-md-12">
												<label
													>
													Description(Optional)
													</label
												>
												<textarea
													class="form-control"
													v-model="record.details"
												/>
											</div>
											<div
												class="form-group col-md-12"
											></div>
										</div>

										<div
											class="modal-footer bg-light justify-content-end"
										>
											<button
												class="btn btn-primary float-right"
											>
												<em
													class="icon ni ni-save"
												></em>
												<span>
													Save Details
												</span>
											</button>
										</div>
									</form>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<div class="card card-bordered loading" v-if="processing">
				<div class="justify-content-center text-center">
					<div class="spinner-grow text-primary m-3" role="status">
						<span class="sr-only">Processing Data...</span>
					</div>
					<h6 class="mb-4">Processing Data...</h6>
				</div>
			</div>

			<div class="card card-bordered" v-else>
				<d-table
					:headers="headers"
					:records="records.data"
					@delete="deleteRecord"
				>
					<template #title="scope">
						{{ scope.record.name }}
					</template>
					<template #details="scope">
						<h1>{{ scope.record.name }}</h1>
					</template>
					<template #form="scope">
						<form
							class="form row"
							@submit.prevent="updateRecord(scope.record)"
						>
							<div class="form-group col-md-12">
								<label>Name</label>
								<input
									class="form-control"
									v-model="scope.record.name"
								/>
							</div>
							<div class="form-group col-md-6 d-none">
								<label>Slug</label>
								<input
									class="form-control"
									v-model="scope.record.slug"
								/>
							</div>
							<div class="form-group col-md-12">
								<label>Description</label>
								<textarea
									class="form-control"
									v-model="scope.record.details"
								/>
							</div>
							<div class="form-group col-md-12">
								<button class="btn btn-primary float-right">
									<em class="icon ni ni-save"></em>
									<span>
										Update {{ scope.record.name }}
									</span>
								</button>
							</div>
						</form>
					</template>
				</d-table>
			</div>

			<paginate
				:data="records"
				@changed="fetchRecords"
				v-if="records.data.length"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";

	export default defineComponent({
		setup(props) {
			const headers = {
				name: "Name",
				slug: "Slug",
			};

			const records = ref({
				data: [],
			});

			const record = reactive({
				name: "",
				details: "",
			});

			const slug = computed(() => {
				return record.name
					.toLocaleLowerCase()
					.replace(",", " ")
					.split(" ")
					.join("-");
			});

			const search = ref("");

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);

			function fetchRecords(page = 1) {
				processing.value = true;
				http.get(`/api/pallet-types?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function searchRecords(e: any) {
				processing.value = true;
				http.get(`/api/pallet-types?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function createRecord() {
				http.post("/api/pallet-types", {
					name: record.name,
					slug: slug.value,
					details: record.details,
				})
					.then((res) => {
						if (res.data.id) {
							created.value = true;

							record.name = "";
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchRecords();
					});
			}

			function updateRecord(r: any) {
				r._method = 'PUT'
				http.post(`/api/pallet-types/${r.id}`, r)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/pallet-types/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			onMounted(() => {
				fetchRecords();
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				createRecord,
				created,
				record,
				slug,
				updateRecord,
				updated,
				deleteRecord,
				search,
				searchRecords,
			};
		},
	});
</script>
